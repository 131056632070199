import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../src/components/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//css
import "./components/service.css";
import "./components/hours.css";
import "./components/salon.css";
import "./components/cut.css";
import "./components/navbar.css";
import "./components/footer.css";
import "./components/home.css";

import Home from "./components/Home.jsx";

import Footer from "../src/components/Footer.jsx";
import Contact from "./components/Contact.jsx";

import Service from "./components/Service.jsx";

import Salon from "./components/Salon.jsx";
import Hours from "./components/Hours.jsx";
import Cut from "./components/Cut.jsx";
import Brides from "./components/Brides.jsx";
import Makeup from "./components/Makeup.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/hours" element={<Hours />} />
        <Route path="/salon" element={<Salon />} />
        <Route path="/cut" element={<Cut />} />
        <Route path="/brides" element={<Brides />} />
        <Route path="/makeup" element={<Makeup />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
