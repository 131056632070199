// src/Cut.jsx
import React from "react";
import Navbar2 from "./Navbar";

const Hours = () => {
  const orari = [
    { giorno: "Lunedì", orario: "9:00 - 18:30" },
    { giorno: "Martedì", orario: "9:00 - 18:30" },
    { giorno: "Mercoledì", orario: "14:00 - 20:00" },
    { giorno: "Giovedì", orario: "9:00 - 18:30" },
    { giorno: "Venerdì", orario: "9:00 - 18:30" },
    { giorno: "Sabato", orario: "9:00 - 18:30" },
    { giorno: "Domenica", orario: "Chiuso" },
  ];

  return (
    <>
      <Navbar2 />
      <div className="cut-container">
        <div className="cut-overlay">
          <h1
            style={{
              fontSize: "30px",
              marginBottom: "20px",
              fontFamily: "Merriweather, serif",
              letterSpacing: "2px",
              marginTop: "70px",
            }}
          >
            Orari del Salone
          </h1>
          <ul style={{ fontFamily: "Montserrat, sans-serif", fontSize: "1rem", color: "white" }}>
            {orari.map((item, index) => (
              <li key={index}>
                <span className="giorno">{item.giorno}</span>: <span className="orario">{item.orario}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Hours;
