import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar1 from "./Navbar";

import Immagine1 from "../assets/Trucco/1.jpg";
import Immagine2 from "../assets/Trucco/2.jpg";
import Immagine3 from "../assets/Trucco/3.jpg";
import Immagine4 from "../assets/Trucco/4.jpg";
import Immagine5 from "../assets/Trucco/5.jpg";
import Immagine6 from "../assets/Trucco/6.jpg";
import Immagine7 from "../assets/Trucco/7.jpg";
import Immagine8 from "../assets/Spose/7.jpg";
import Immagine9 from "../assets/Spose/12.jpg";

import { Container, Row, Col, Card } from "react-bootstrap";

const Makeup = () => {
  const images = [
    { src: Immagine1, alt: "Immagine 1" },
    { src: Immagine2, alt: "Immagine 2" },
    { src: Immagine3, alt: "Immagine 3" },
    { src: Immagine4, alt: "Immagine 4" },
    { src: Immagine5, alt: "Immagine 5" },
    { src: Immagine6, alt: "Immagine 6" },
    { src: Immagine7, alt: "Immagine 7" },
    { src: Immagine8, alt: "Immagine 8" },
    { src: Immagine9, alt: "Immagine 9" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <Navbar1 />
      {/* Video di background */}
      <Container fluid style={{ padding: "0px", position: "relative" }}>
        <video
          autoPlay
          muted
          loop
          playsInline
          controls={false}
          className="background-video"
          style={{ width: "100%" }}
          id="video"
        >
          <source src={process.env.PUBLIC_URL + "/assets/makeup.mp4"} type="video/mp4" />
          Il tuo browser non supporta il tag video.
        </video>
      </Container>

      <Container className="margin-top-custom" style={{ marginTop: "20px" }}>
        {/* <h2 className="section-title-cut">Taglio e colore</h2> */}
        <Card.Body className="p-5">
          <div className="section-title">
            <h2
              className=" text-center mb-2"
              style={{
                color: "#5d1657",
                fontSize: "25px",
                marginBottom: "20px",
                fontFamily: "Merriweather, serif",
                letterSpacing: "2px",
              }}
            >
              Makeup
            </h2>
            <div className="divider mb-4" style={{ backgroundColor: "#5d1657" }}></div>
          </div>
          <Card.Text
            style={{
              textAlign: "justify",
              lineHeight: "1.6",
              fontFamily: "Montserrat, sans-serif",
            }}
            className="my-text"
          >
            Benvenuti nella sezione Trucco di Art Paris Total Look, dove la bellezza incontra l'arte. Il nostro team di
            esperti make-up artist realizza look impeccabili, personalizzati per valorizzare i tuoi tratti unici.
            Utilizziamo prodotti di alta qualità e tecniche avanzate per garantire un trucco duraturo e perfetto per
            ogni occasione.
          </Card.Text>
        </Card.Body>
        <Row>
          {images.map((image, index) => (
            <Col key={index} xs={12} md={6} lg={4} className="mb-4">
              <div className="image-container">
                <img src={image.src} alt={image.alt} className="img-fluid fixed-dimension" />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Makeup;
