// import React, { useState, useEffect } from "react";
// import { Navbar as BootstrapNavbar, Nav, Container, Modal, Image } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import Logo from "../assets/navbar/logo.png";
// import FacebookIcon from "../assets/navbar/facebook.png";
// import InstagramIcon from "../assets/navbar/instagram.png";
// import EmailIcon from "../assets/navbar/email.png";

// const Navbar = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [activeLink, setActiveLink] = useState(window.location.pathname);

//   const handleModalOpen = () => setShowModal(true);
//   const handleModalClose = () => setShowModal(false);

//   const handleScroll = () => {
//     const scrolled = window.scrollY > 100;
//     setIsScrolled(scrolled);
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <>
//       <BootstrapNavbar
//         expand="lg"
//         className={`custom-navbar navbar-purple fixed-top ${isScrolled ? "navbar-transparent" : ""}`}
//       >
//         <Container fluid className="justify-content-between align-items-center">
//           {/* Logo a sinistra */}
//           <BootstrapNavbar.Brand as={Link} to="/">
//             <Image src={Logo} alt="Logo" height="50" style={{ marginLeft: "10px" }} />
//           </BootstrapNavbar.Brand>

//           {/* Toggle per il Modal su schermi piccoli */}
//           <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" onClick={handleModalOpen} />

//           {/* Voci di menu */}
//           <Nav className="ms-auto gap-5 d-none d-lg-flex mx-auto text-white">
//             <Nav.Link
//               as={Link}
//               to="/service"
//               className={`nav-link nav-item ${activeLink === "/service" ? "active" : ""}`}
//               onClick={() => setActiveLink("/service")}
//               style={{ color: "white", fontSize: "14px", fontFamily: "Merriweather, serif", letterSpacing: "2px" }}
//             >
//               <span>SERVIZI</span>
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/hours"
//               className={`nav-link nav-item ${activeLink === "/hours" ? "active" : ""}`}
//               onClick={() => setActiveLink("/hours")}
//               style={{ color: "white", fontSize: "14px", fontFamily: "Merriweather, serif", letterSpacing: "2px" }}
//             >
//               <span>ORARI</span>
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/"
//               className={`nav-link nav-item ${activeLink === "/" ? "active" : ""}`}
//               onClick={() => setActiveLink("/")}
//               style={{
//                 color: "white",
//                 fontSize: "14px",
//                 marginLeft: "20px",
//                 marginRight: "20px",
//                 fontFamily: "Merriweather, serif",
//               }}
//             >
//               <span>HOME</span>
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/salon"
//               className={`nav-link nav-item ${activeLink === "/salon" ? "active" : ""}`}
//               onClick={() => setActiveLink("/salon")}
//               style={{ color: "white", fontSize: "14px", fontFamily: "Merriweather, serif", letterSpacing: "2px" }}
//             >
//               <span>SALONE</span>
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/contact"
//               className={`nav-link nav-item ${activeLink === "/contact" ? "active" : ""}`}
//               onClick={() => setActiveLink("/contact")}
//               style={{ color: "white", fontSize: "14px", fontFamily: "Merriweather, serif", letterSpacing: "2px" }}
//             >
//               <span style={{ marginRight: "40px" }}>CONTATTI</span>
//             </Nav.Link>
//           </Nav>

//           {/* Icone social a destra */}
//           <div className="d-none d-lg-flex align-items-center">
//             <a
//               href="https://www.facebook.com/artparis.total.look/?ref=hl"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-white mx-2"
//             >
//               <Image src={FacebookIcon} alt="Facebook" height="30" className="footer-icon" />
//             </a>
//             <a
//               href="https://www.instagram.com/artparis_total_look_rome/?utm_medium=copy_link"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-white mx-2"
//             >
//               <Image src={InstagramIcon} alt="Instagram" height="30" className="footer-icon" />
//             </a>
//             <a href="mailto:artparis_2@yahoo.it" className="text-white mx-2">
//               <Image src={EmailIcon} alt="Email" height="30" className="footer-icon" style={{ marginRight: "6px" }} />
//             </a>
//           </div>
//         </Container>
//       </BootstrapNavbar>

//       {/* Modal per schermi piccoli */}
//       <Modal show={showModal} onHide={handleModalClose} backdrop={false} dialogClassName="custom-modal">
//         <Modal.Header closeButton>
//           <Modal.Title>Menu</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Nav className="flex-column">
//             <Nav.Link
//               as={Link}
//               to="/service"
//               className={`nav-link nav-item ${activeLink === "/service" ? "active" : ""}`}
//               onClick={() => {
//                 setActiveLink("/service");
//                 handleModalClose();
//               }}
//               style={{ fontFamily: "Merriweather, serif" }}
//             >
//               <span style={{ color: "#663455" }}>Servizi</span>
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/hours"
//               className={`nav-link nav-item ${activeLink === "/hours" ? "active" : ""}`}
//               onClick={() => {
//                 setActiveLink("/hours");
//                 handleModalClose();
//               }}
//               style={{ fontFamily: "Merriweather, serif" }}
//             >
//               <span style={{ color: "#663455" }}>Orari</span>
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/"
//               className={`nav-link nav-item ${activeLink === "/" ? "active" : ""}`}
//               onClick={() => {
//                 setActiveLink("/");
//                 handleModalClose();
//               }}
//               style={{ fontFamily: "Merriweather, serif" }}
//             >
//               <span style={{ color: "#663455" }}>Home</span>
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/salon"
//               className={`nav-link nav-item ${activeLink === "/salon" ? "active" : ""}`}
//               onClick={() => {
//                 setActiveLink("/salon");
//                 handleModalClose();
//               }}
//               style={{ fontFamily: "Merriweather, serif" }}
//             >
//               <span style={{ color: "#663455" }}>Salone</span>
//             </Nav.Link>
//             <Nav.Link
//               as={Link}
//               to="/contact"
//               className={`nav-link nav-item ${activeLink === "/contact" ? "active" : ""}`}
//               onClick={() => {
//                 setActiveLink("/contact");
//                 handleModalClose();
//               }}
//               style={{ fontFamily: "Merriweather, serif" }}
//             >
//               <span style={{ color: "#663455" }}>Contatti</span>
//             </Nav.Link>
//           </Nav>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default Navbar;

//----

// import React, { useState } from "react";
// import { Nav } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import Logo from "../assets/navbar/logo.png"; // Assicurati che il percorso del logo sia corretto

// const Navbar = () => {
//   const [activeLink, setActiveLink] = useState(window.location.pathname);

//   return (
//     <div className="custom-navbar">
//       {/* Voci di menu a destra */}
//       <Nav>
//         <Nav.Link
//           as={Link}
//           to="/"
//           className={`nav-link ${activeLink === "/" ? "active" : ""}`}
//           onClick={() => setActiveLink("/")}
//           style={{ color: "white", fontSize: "14px", fontFamily: "Merriweather, serif", letterSpacing: "2px" }}
//         >
//           Home
//         </Nav.Link>
//         <Nav.Link
//           as={Link}
//           to="/service"
//           className={`nav-link ${activeLink === "/about" ? "active" : ""}`}
//           onClick={() => setActiveLink("/about")}
//         >
//           Servizi
//         </Nav.Link>
//         <Nav.Link
//           as={Link}
//           to="/hours"
//           className={`nav-link ${activeLink === "/catalog" ? "active" : ""}`}
//           onClick={() => setActiveLink("/catalog")}
//         >
//           Orari
//         </Nav.Link>
//         <Nav.Link
//           as={Link}
//           to="/salon"
//           className={`nav-link ${activeLink === "/contact" ? "active" : ""}`}
//           onClick={() => setActiveLink("/contact")}
//         >
//           Salone
//         </Nav.Link>
//         <Nav.Link
//           as={Link}
//           to="/contact"
//           className={`nav-link ${activeLink === "/contact" ? "active" : ""}`}
//           onClick={() => setActiveLink("/contact")}
//         >
//           Contatti
//         </Nav.Link>
//       </Nav>
//     </div>
//   );
// };

// export default Navbar;

import React, { useState } from "react";
import { Nav, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState(window.location.pathname);
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <>
      <div className="custom-navbar d-none d-md-flex" style={{ display: "flex", alignItems: "center" }}>
        {/* Voci di menu a destra per desktop */}
        <Nav style={{ flex: 1, display: "flex", alignItems: "center" }}>
          {/* Scritta come immagine */}
          <Nav.Link
            as={Link}
            to="/"
            className={`nav-link ${activeLink === "/" ? "active" : ""}`}
            onClick={() => {
              setActiveLink("/");
              handleModalClose();
            }}
            style={{ padding: 0, marginRight: "auto" }} // Rimuovi padding per allineare meglio
          >
            <img
              src="/assets/scritta.png"
              alt="Logo"
              style={{
                height: "50px", // Altezza fissa per mantenere proporzioni
                maxWidth: "150px", // Dimensione massima della larghezza
                objectFit: "contain", // Mantiene le proporzioni dell'immagine
                marginRight: "0px",
              }}
            />
          </Nav.Link>

          {/* Le voci del menù */}
          <Nav.Link
            as={Link}
            to="/"
            className={`nav-link ${activeLink === "/" ? "active" : ""}`}
            onClick={() => setActiveLink("/")}
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            Home
          </Nav.Link>

          <Nav.Link
            as={Link}
            to="/salon"
            className={`nav-link ${activeLink === "/service" ? "active" : ""}`}
            onClick={() => setActiveLink("/service")}
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            Salone
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/service"
            className={`nav-link ${activeLink === "/hours" ? "active" : ""}`}
            onClick={() => setActiveLink("/hours")}
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            Servizi
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/contact"
            className={`nav-link ${activeLink === "/salon" ? "active" : ""}`}
            onClick={() => setActiveLink("/salon")}
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            Contatti
          </Nav.Link>
        </Nav>
      </div>

      {/* Pulsante hamburger visibile solo in modalità mobile */}
      {/* <div className=" text-center">
        <Button
          variant="link"
          onClick={handleModalOpen}
          style={{ padding: "10px", backgroundColor: "transparent", border: "none" }}
        >
          <div className="hamburger-icon">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </Button>
      </div> */}
      <div className="hamburger-container">
        <Button
          variant="link"
          onClick={handleModalOpen}
          style={{ padding: "10px", backgroundColor: "transparent", border: "none", marginLeft: "44%" }}
        >
          <div className="hamburger-icon">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </Button>
      </div>

      {/* Modal per schermi piccoli */}
      <Modal show={showModal} onHide={handleModalClose} backdrop="static" dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav className="flex-column">
            <Nav.Link
              as={Link}
              to="/"
              className={`nav-link nav-item ${activeLink === "/" ? "active" : ""}`}
              onClick={() => {
                setActiveLink("/");
                handleModalClose();
              }}
              style={{ fontFamily: "Merriweather, serif" }}
            >
              <span style={{ color: "#663455" }}>Home</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/service"
              className={`nav-link nav-item ${activeLink === "/service" ? "active" : ""}`}
              onClick={() => {
                setActiveLink("/service");
                handleModalClose();
              }}
              style={{ fontFamily: "Merriweather, serif" }}
            >
              <span style={{ color: "#663455" }}>Servizi</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/hours"
              className={`nav-link nav-item ${activeLink === "/hours" ? "active" : ""}`}
              onClick={() => {
                setActiveLink("/hours");
                handleModalClose();
              }}
              style={{ fontFamily: "Merriweather, serif" }}
            >
              <span style={{ color: "#663455" }}>Orari</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/salon"
              className={`nav-link nav-item ${activeLink === "/salon" ? "active" : ""}`}
              onClick={() => {
                setActiveLink("/salon");
                handleModalClose();
              }}
              style={{ fontFamily: "Merriweather, serif" }}
            >
              <span style={{ color: "#663455" }}>Salone</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              className={`nav-link nav-item ${activeLink === "/contact" ? "active" : ""}`}
              onClick={() => {
                setActiveLink("/contact");
                handleModalClose();
              }}
              style={{ fontFamily: "Merriweather, serif" }}
            >
              <span style={{ color: "#663455" }}>Contatti</span>
            </Nav.Link>
          </Nav>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
