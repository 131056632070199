import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar1 from "./Navbar";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS CSS
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Import delle immagini
import ImmagineTaglio from "../assets/home/su_di_noi.jpg";
import ImmagineSpose from "../assets/home/spose.jpg";
import ImmagineTrucco from "../assets/home/9.jpg";
import ImageGallery1 from "../assets/home/1.jpg";
import ImageGallery2 from "../assets/home/5.jpg";
import ImageGallery3 from "../assets/salone/taglio.jpg";
import ImageGallery4 from "../assets/home/2.jpeg";
import ImageGallery5 from "../assets/home/15.png";
import ImageGallery6 from "../assets/home/13.jpg";
import ImageGallery7 from "../assets/home/4.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".cut-container", {
      backgroundPosition: "50% 100%", // Scorrimento dell'immagine
      ease: "none",
      scrollTrigger: {
        trigger: ".cut-container",
        scrub: true,
      },
    });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durata animazione
      once: true, // L'animazione avviene una sola volta
    });
  }, []);

  const orari = [
    { giorno: "Lunedì", orario: "9:00 - 18:30" },
    { giorno: "Martedì", orario: "9:00 - 18:30" },
    { giorno: "Mercoledì", orario: "14:00 - 20:00" },
    { giorno: "Giovedì", orario: "9:00 - 18:30" },
    { giorno: "Venerdì", orario: "9:00 - 18:30" },
    { giorno: "Sabato", orario: "9:00 - 18:30" },
    { giorno: "Domenica", orario: "Chiuso" },
  ];

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const navigate = useNavigate();

  const handleNavigate = (path) => {
    window.scrollTo(0, 0); // Scrolla subito in cima alla pagina
    navigate(path); // Cambia percorso
  };

  // Stili per la galleria delle immagini
  const galleryItemStyle = {
    position: "relative",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    transition: "transform 0.3s ease-out",
    cursor: "pointer",
    height: "250px",
    borderRadius: "5px",
  };

  const galleryImageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Per mantenere le proporzioni e coprire l'intera area
    transition: "opacity 0.3s",
  };

  const galleryOverlayStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: "0",
    transition: "opacity 0.3s",
    fontSize: "10px",
  };

  const galleryItemHoverStyle = {
    opacity: "1",
  };

  return (
    <>
      {/* Navbar */}
      <Navbar1 />
      {/* Video di background */}
      <Container fluid style={{ padding: "0px", position: "relative" }}>
        <video
          autoPlay
          muted
          loop
          playsInline
          controls={false}
          className="background-video"
          style={{ width: "100%" }}
          id="video"
        >
          <source src={process.env.PUBLIC_URL + "/assets/prova.mp4"} type="video/mp4" />
          Il tuo browser non supporta il tag video.
        </video>
      </Container>
      {/* Sezione "Su di noi" con animazione */}
      <section style={{ backgroundColor: "#F6F6F6", padding: "60px 0", textAlign: "center" }}>
        <div className="about-content" data-aos="fade-up">
          {/* Titolo */}
          <h2 data-aos="fade-up" className="about-title">
            Su di noi
          </h2>
          {/* Testo Descrittivo */}

          <div className="container">
            <p
              data-aos="fade-up"
              data-aos-delay="300"
              style={{
                fontSize: "15px",
                letterSpacing: "1px",
              }}
              className="about-text"
            >
              Art Paris è un salone di bellezza fondato nel dicembre 2002 da tre collaboratrici e gestito ora da Roberta
              Paris e Alessandra Orsini. Entrambe hanno acquisito esperienza presso l'accademia di trucco e acconciatura
              Gil Cagnè Barlaam, famosa per il lavoro con celebrità televisive. Il salone è cresciuto grazie alla
              passione delle fondatrici e alla fiducia dei clienti, offrendo un'atmosfera accogliente e familiare.
            </p>
          </div>
          {/* Immagini con animazione */}
          <div className="about-images">
            <img src={ImageGallery5} alt="Gallery 1" data-aos="zoom-in" data-aos-delay="400" />
            <img src={ImageGallery6} alt="Gallery 2" data-aos="zoom-in" data-aos-delay="600" />
            <img src={ImageGallery7} alt="Gallery 3" data-aos="zoom-in" data-aos-delay="800" />
          </div>
        </div>
      </section>
      {/* Orari del salone */}
      {/* <section>
        <div className="cut-container">
          <div className="cut-overlay">
            <div className="text-background">
              <h1
                style={{
                  fontSize: "26px",
                  marginBottom: "30px",
                  fontFamily: "Merriweather, serif",
                  letterSpacing: "2px",
                  marginTop: "70px",
                  color: "#fff",
                  textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
                }}
              >
                Orari del Salone
              </h1>
              <ul
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "1.2rem",
                  color: "#fff",
                  listStyle: "none",
                  padding: 0,
                  textAlign: "center",
                  lineHeight: "2",
                  textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)",
                }}
              >
                {orari.map((item, index) => (
                  <li key={index} style={{ marginBottom: "10px" }}>
                    <span className="giorno" style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                      {item.giorno}
                    </span>
                    :{" "}
                    <span className="orario" style={{ fontSize: "1.1rem", opacity: 0.9 }}>
                      {item.orario}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="cut-container">
          <div className="cut-overlay">
            <div className="text-background">
              <h1
                style={{
                  marginBottom: "30px",
                  fontFamily: "Merriweather, serif",
                  letterSpacing: "2px",
                  marginTop: "70px",
                  color: "#fff",
                  textShadow: "2px 2px 6px rgba(0, 0, 0, 0.6)",
                  fontSize: "30px",
                }}
              >
                Orari del Salone
              </h1>
              <ul
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "1.2rem",
                  color: "#fff",
                  listStyle: "none",
                  padding: 0,
                  textAlign: "center",
                  lineHeight: "2",
                  textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
                }}
              >
                {orari.map((item, index) => (
                  <li key={index} style={{ marginBottom: "10px" }}>
                    <span className="giorno" style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                      {item.giorno}
                    </span>
                    :{" "}
                    <span className="orario" style={{ fontSize: "1.1rem", opacity: 0.9 }}>
                      {item.orario}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Sezione Servizi */}
      <section className="services-section " style={{ marginTop: "120px", marginBottom: "120px" }}>
        <div className="container">
          <div className="row text-center">
            {/* Servizio Taglio-Colore */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              {" "}
              {/* Apply AOS animation */}
              <div
                className="service-card"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <img
                  src={ImmagineTaglio}
                  alt="Immagina"
                  className="service-image img-fluid mb-3"
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                />
                <h3
                  className="service-title"
                  style={{
                    fontSize: "25px",
                    marginBottom: "10px",
                    fontFamily: "Merriweather, serif",
                    letterSpacing: "1px",
                  }}
                >
                  Con noi
                </h3>
                <h4 className="service-subtitle">TAGLIO E COLORE</h4>
                <p>Idee · Originalità</p>
                <button
                  onClick={() => handleNavigate("/cut")}
                  className="btn btn-outline-dark"
                  style={{ marginTop: "auto" }}
                >
                  Scopri
                </button>
              </div>
            </div>

            {/* Servizio Sposa */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              {" "}
              {/* Apply AOS animation */}
              <div
                className="service-card"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px",
                  backgroundColor: "#412055",
                  color: "white",
                }}
              >
                <img
                  src={ImmagineSpose}
                  alt="Cambia"
                  className="service-image img-fluid mb-3"
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                />
                <h3
                  className="service-title"
                  style={{
                    fontSize: "25px",
                    marginBottom: "10px",
                    fontFamily: "Merriweather, serif",
                    letterSpacing: "1px",
                  }}
                >
                  Puoi
                </h3>
                <h4 className="service-subtitle" style={{ color: "#CDB587" }}>
                  SPOSA
                </h4>
                <p>Bellezza · Raffinatezza</p>
                <button
                  onClick={() => handleNavigate("/brides")}
                  className="btn btn-outline-light"
                  style={{ marginTop: "auto" }}
                >
                  Scopri
                </button>
              </div>
            </div>

            {/* Servizio Trucco */}
            <div className="col-md-4 mb-4" data-aos="fade-up">
              {" "}
              {/* Apply AOS animation */}
              <div
                className="service-card"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <img
                  src={ImmagineTrucco}
                  alt="Splendi"
                  className="service-image img-fluid mb-3"
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                />
                <h3
                  className="service-title"
                  style={{
                    fontSize: "25px",
                    marginBottom: "10px",
                    fontFamily: "Merriweather, serif",
                    letterSpacing: "1px",
                  }}
                >
                  Splendere
                </h3>
                <h4 className="service-subtitle">TRUCCO</h4>
                <p>Arte · Creatività</p>
                <button
                  onClick={() => handleNavigate("/makeup")}
                  className="btn btn-outline-dark"
                  style={{ marginTop: "auto" }}
                >
                  Scopri
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Sezione Frase Elegante */}
      <section style={{ backgroundColor: "#E4D2E2", padding: "60px 0", textAlign: "center" }}>
        <div className="container">
          <h2
            data-aos="fade-down"
            data-aos-duration="1000" // Durata in millisecondi
            style={{
              fontSize: "32px",
              fontFamily: "Merriweather, serif",
              letterSpacing: "2px",
              marginBottom: "20px",
              color: "#5d1657",
              textShadow: "1px 1px 4px rgba(0,0,0,0.2)",
            }}
          >
            Collaborazioni
          </h2>

          <div
            data-aos="zoom-in"
            data-aos-delay="200" // Ritardo in millisecondi
            data-aos-duration="1000"
            style={{
              width: "100px",
              height: "3px",
              backgroundColor: "#5d1657",
              margin: "0 auto 20px auto",
            }}
          ></div>

          <p
            data-aos="fade-up"
            data-aos-delay="400" // Ritardo per l'entrata successiva
            data-aos-duration="1000"
            style={{
              fontSize: "16px",
              letterSpacing: "1px",
              color: "#333",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Art Paris collabora con professionisti come il dermatologo Dr. Raffaele Murace e l'estetista e
            dermopigmentatrice Serena Masotti, che offre giornate di consulenza nel salone. Roberta e Alessandra offrono
            servizi di qualità, guidate da ascolto, professionalità, sincerità e allegria.
          </p>
        </div>
      </section>

      {/* Sezione Scopri di più */}
      {/* <section style={{ backgroundColor: "#F6F6F6", padding: "60px 0", textAlign: "center" }}>
        <div className="container">
          <p
            style={{
              color: "#5d1657",
              fontSize: "20px",
              marginBottom: "20px",
              fontFamily: "Merriweather, serif",
              letterSpacing: "2px",
            }}
          >
            PROFESSIONALITA' E ALLEGRIA
          </p>
          <div className="divider mb-4" style={{ backgroundColor: "#663455" }}></div>

          <p
            style={{
              fontSize: "15px",
              letterSpacing: "1px",
            }}
            className="about-text"
          >
            Art Paris collabora con professionisti come il dermatologo Dr. Raffaele Murace e l'estetista e
            dermopigmentatrice Sonia Pignataro, che offre giornate di consulenza nel salone. Roberta e Alessandra si
            rivolgono a una clientela ampia, puntando sull'ascolto per offrire servizi di alta qualità con
            professionalità e sincerità. Professionalità, sincerità e allegria sono i tratti distintivi di Art Paris.
          </p>
        </div>
      </section> */}
      {/* Sezione Galleria Fotografica */}
      {/* <section className="photo-gallery py-5">
        <Container className="mt-4 mb-4">
          <Row>
            {[
              { image: ImageGallery1, text: "Accoglienza" },
              { image: ImageGallery2, text: "Eccellenza" },
              { image: ImageGallery3, text: "Professionalità" },
              { image: ImageGallery4, text: "Attenzione" },
            ].map((item, index) => (
              <Col
                key={index}
                xs={12}
                sm={6}
                md={3}
                className="mb-4"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="gallery-item" style={galleryItemStyle}>
                  <img
                    src={item.image}
                    alt={`Gallery ${index + 1}`}
                    className="gallery-image"
                    style={galleryImageStyle}
                  />
                  <div
                    className="gallery-overlay"
                    style={{ ...galleryOverlayStyle, ...(hoveredItem === index && galleryItemHoverStyle) }}
                  >
                    <p style={{ fontSize: "1.5em", fontFamily: "Montserrat, sans-serif" }}>{item.text}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Home;
