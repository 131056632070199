import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import Navbar2 from "./Navbar";
import ImmagineCopertina from "../assets/servizi/img-copertina-art-paris (7).png";

const Service = () => {
  return (
    <>
      <Navbar2 />
      <Container fluid style={{ padding: "0px", position: "relative", textAlign: "center" }}>
        <img
          src={ImmagineCopertina}
          alt="Copertina"
          className="img-fluid"
          id="responsive"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Container>

      <Container className="py-5">
        <Row className="mb-4">
          <Col>
            <div className="section-title">
              <h2
                className=" text-center mb-2"
                style={{
                  color: "#5d1657",
                  fontSize: "25px",
                  marginBottom: "20px",
                  fontFamily: "Merriweather, serif",
                  letterSpacing: "2px",
                }}
              >
                Capelli
              </h2>
              <div className="divider mb-4" style={{ backgroundColor: "#663455" }}></div>
            </div>
            <Card className="shadow-sm">
              <Card.Body>
                <Table responsive bordered className="custom-table">
                  <thead>
                    <tr>
                      <th>Servizio</th>
                      <th>Prezzo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Messa in Piega (con proprio Shampoo di farmacia) </td>
                      <td>€ 14,50</td>
                    </tr>
                    <tr>
                      <td>M.P. più trattamento specifico</td>
                      <td>€ 18,00</td>
                    </tr>
                    <tr>
                      <td>Piega più piastra liscia</td>
                      <td>€ 21,50</td>
                    </tr>
                    <tr>
                      <td>Piega più piastra mossa</td>
                      <td>€ 23,00</td>
                    </tr>
                    <tr>
                      <td>Semiraccolto</td>
                      <td>€ 30,00</td>
                    </tr>
                    <tr>
                      <td>Raccolto</td>
                      <td>€ 50,00</td>
                    </tr>
                    <tr>
                      <td>Taglio</td>
                      <td>€ 23,50</td>
                    </tr>
                    <tr>
                      <td>Ritocco mensile colore</td>
                      <td>€ 23,50</td>
                    </tr>
                    <tr>
                      <td>Tono su tono</td>
                      <td>€ 24,50</td>
                    </tr>
                    <tr>
                      <td>Cambio colore</td>
                      <td>€ 45,00</td>
                    </tr>
                    <tr>
                      <td>Degradeè di colore</td>
                      <td>€ 55,00</td>
                    </tr>
                    <tr>
                      <td>Bagno di colore</td>
                      <td>€ 18,50</td>
                    </tr>
                    <tr>
                      <td>Colpi di sole</td>
                      <td>€ 45,00</td>
                    </tr>
                    <tr>
                      <td>Sfumature multi color</td>
                      <td>€ 60,00</td>
                    </tr>
                    <tr>
                      <td>Lunex</td>
                      <td>da € 35,00 a € 60,00</td>
                    </tr>
                    <tr>
                      <td>Hennè (applicazione)</td>
                      <td>€ 35,00</td>
                    </tr>
                    <tr>
                      <td>Permanente/Tiraggio</td>
                      <td>€ 65,00</td>
                    </tr>
                    <tr>
                      <td>Taglio uomo artistico</td>
                      <td>€ 30,00</td>
                    </tr>
                    <tr>
                      <td>Taglio più shampoo uomo (+12)</td>
                      <td>€ 20,00</td>
                    </tr>
                    <tr>
                      <td>Taglio under 12 (maschio)</td>
                      <td>€ 18,00</td>
                    </tr>
                    <tr>
                      <td>Taglio under 12 (femmina, no shampoo)</td>
                      <td>€ 25,00</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <div className="section-title">
              <h2
                className=" text-center mb-2"
                style={{
                  color: "#5d1657",
                  fontSize: "25px",
                  marginBottom: "20px",
                  fontFamily: "Merriweather, serif",
                  letterSpacing: "2px",
                }}
              >
                Trucco e Maquillage
              </h2>
              <div className="divider mb-4" style={{ backgroundColor: "#663455" }}></div>
            </div>
            <Card className="shadow-sm">
              <Card.Body>
                <Table responsive bordered className="custom-table">
                  <thead>
                    <tr>
                      <th>Servizio</th>
                      <th>Prezzo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sopracciglia studio forma</td>
                      <td>€ 25,00</td>
                    </tr>
                    <tr>
                      <td>Pulizia settimanale</td>
                      <td>€ 15,00</td>
                    </tr>
                    <tr>
                      <td>Applicazione ciglia a ciuffetti</td>
                      <td>da € 15,00</td>
                    </tr>
                    <tr>
                      <td>Trucco</td>
                      <td>da € 50,00</td>
                    </tr>
                    <tr>
                      <td>Lezione trucco</td>
                      <td>da € 70,00</td>
                    </tr>
                    <tr>
                      <td>Mini corso trucco</td>
                      <td>da € 150,00</td>
                    </tr>
                  </tbody>
                </Table>
                <p className="text-center" style={{ fontStyle: "italic" }}>
                  * Trattamenti Specifici prezzi da convenire.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Service;
