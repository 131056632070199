import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar1 from "./Navbar";

import Immagine1 from "../assets/Spose/1.jpg";
import Immagine2 from "../assets/Spose/2.jpg";
import Immagine3 from "../assets/Spose/3.jpg";
import Immagine4 from "../assets/Spose/4.jpg";
import Immagine5 from "../assets/Spose/5.jpg";
import Immagine6 from "../assets/Spose/6.jpeg";
import Immagine7 from "../assets/Spose/7.jpg";
import Immagine8 from "../assets/Spose/8.jpg";
import Immagine9 from "../assets/Spose/9.jpg";
import Immagine10 from "../assets/Spose/10.jpg";
import Immagine11 from "../assets/Spose/11.jpg";
import Immagine12 from "../assets/Spose/12.jpg";
import Immagine13 from "../assets/Spose/13.jpg";
import Immagine14 from "../assets/Spose/14.jpg";
import Immagine15 from "../assets/Spose/15.jpg";
import { Container, Row, Col, Card } from "react-bootstrap";

const Brides = () => {
  const images = [
    { src: Immagine1, alt: "Immagine 1" },
    { src: Immagine2, alt: "Immagine 2" },
    { src: Immagine3, alt: "Immagine 3" },
    { src: Immagine4, alt: "Immagine 4" },
    { src: Immagine5, alt: "Immagine 5" },
    { src: Immagine6, alt: "Immagine 6" },
    { src: Immagine7, alt: "Immagine 7" },
    { src: Immagine8, alt: "Immagine 8" },
    { src: Immagine9, alt: "Immagine 9" },
    { src: Immagine10, alt: "Immagine 10" },
    { src: Immagine11, alt: "Immagine 11" },
    { src: Immagine12, alt: "Immagine 12" },
    { src: Immagine13, alt: "Immagine 13" },
    { src: Immagine14, alt: "Immagine 14" },
    { src: Immagine15, alt: "Immagine 15" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <Navbar1 />
      {/* Video di background */}
      <Container fluid style={{ padding: "0px", position: "relative" }}>
        <video
          autoPlay
          muted
          loop
          playsInline
          controls={false}
          className="background-video"
          style={{ width: "100%" }}
          id="video"
        >
          <source src={process.env.PUBLIC_URL + "/assets/brides.mp4"} type="video/mp4" />
          Il tuo browser non supporta il tag video.
        </video>
      </Container>

      <Container className="margin-top-custom" style={{ marginTop: "-1px" }}>
        <Card.Body className="p-5">
          <div className="section-title">
            <h2
              className=" text-center mb-2"
              style={{
                color: "#5d1657",
                fontSize: "25px",
                marginBottom: "20px",
                fontFamily: "Merriweather, serif",
                letterSpacing: "2px",
              }}
            >
              Spose
            </h2>
            <div className="divider mb-4" style={{ backgroundColor: "#5d1657" }}></div>
          </div>
          <Card.Text
            style={{
              textAlign: "justify",
              lineHeight: "1.6",
              fontFamily: "Montserrat, sans-serif",
            }}
            className="my-text"
          >
            Benvenuti nella sezione spose di Art Paris Total Look. Il nostro team di professionisti trasforma la tua
            visione in realtà, creando acconciature e trucchi eleganti, sofisticati che rispecchiano la tua personalità.
            Con attenzione, professionalità e un tocco di magia, rendiamo ogni sposa radiosa e indimenticabile.
          </Card.Text>
        </Card.Body>
        <Row>
          {images.map((image, index) => (
            <Col key={index} xs={12} md={6} lg={4} className="mb-4">
              <div className="image-container">
                <img src={image.src} alt={image.alt} className="img-fluid fixed-dimension" />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Brides;
