import React from "react";
import facebookIcon from "../assets/social-icon/facebook.svg";
import instagramIcon from "../assets/social-icon/ig.svg";
import googleIcon from "../assets/social-icon/google.svg";
import footerImage from "../assets/footer/logo.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          Art Paris - Total look | Via Petritoli 12/14 - 00138 Roma (RM)
          {/* <span role="img" aria-label="heart">
            🤍
          </span> */}
        </p>
        <div className="social-icons">
          <a
            href="https://www.instagram.com/artparis_total_look_rome/?utm_medium=copy_link"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            <img src={instagramIcon} alt="Instagram" className="footer-icon" />
          </a>
          <a
            href="https://www.facebook.com/artparis.total.look/?ref=hl"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            <img src={facebookIcon} alt="Facebook" className="footer-icon" />
          </a>
          {/* <a href="mailto:artparis_2@yahoo.it" className="nav-link">
            <img src={emailIcon} alt="Email" className="footer-icon" />
          </a> */}
          <a
            href="https://www.google.com/maps/place/Art+Paris+Snc/@41.9825321,12.5101876,16z/data=!4m22!1m13!4m12!1m4!2m2!1d12.5223565!2d41.9808693!4e1!1m6!1m2!1s0x132f6689fce8f2b1:0x9aadcf62af62aed7!2sArt+Paris+Snc+Recensioni!2m2!1d12.5127625!2d41.9825281!3m7!1s0x132f6689fce8f2b1:0x9aadcf62af62aed7!8m2!3d41.9825281!4d12.5127625!9m1!1b1!16s%2Fg%2F1tdj6c0_?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            <img src={googleIcon} alt="google" className="footer-icon" />
          </a>
        </div>
        <div className="footer-image">
          <a href="https://www.femcode.it/" target="_blank" rel="noopener noreferrer">
            <img
              src={footerImage}
              alt="Footer Decorative"
              style={{ width: "107px", height: "auto", marginTop: "7px" }}
            />
          </a>
        </div>
      </div>
      {/* <div className="scroll-to-top" onClick={scrollToTop}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4L4 12H8V20H16V12H20L12 4Z" fill="white" />
        </svg>
      </div> */}
    </footer>
  );
};

export default Footer;
