import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Navbar2 from "./Navbar";

// Import immagini
import ImmagineCopertina from "../assets/salone/img-copertina-art-paris (12).png";
import Immagine1 from "../assets/salone/1.jpg";
import Immagine2 from "../assets/salone/2.jpg";
import Immagine3 from "../assets/salone/3.jpg";
import Immagine4 from "../assets/salone/4.jpg";
import Immagine5 from "../assets/salone/5.jpg";
import Immagine6 from "../assets/salone/6.jpg";
import Immagine7 from "../assets/salone/7.jpg";
import Immagine8 from "../assets/salone/8.jpg";
import Immagine9 from "../assets/salone/taglio.jpg";

import BackgroundImage from "../assets/salone/scritta-salon.png";

const Salon = () => {
  return (
    <>
      <Navbar2 />
      <Container fluid style={{ padding: "0px", position: "relative", textAlign: "center" }}>
        <img
          src={ImmagineCopertina}
          alt="Copertina"
          className="img-fluid"
          id="responsive"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Container>
      <Container fluid className="py-5 bg-light position-relative">
        <div className="background-image-container">
          <img src={BackgroundImage} alt="Background" className="background-image" />
        </div>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="border-0 text-center bg-white shadow-lg position-relative">
              <Card.Body className="p-5">
                <div className="section-title">
                  <h2
                    className=" mb-2"
                    style={{
                      color: "#5d1657",
                      fontSize: "25px",
                      marginBottom: "20px",
                      fontFamily: "Merriweather, serif",
                      letterSpacing: "2px",
                    }}
                  >
                    Chi siamo
                  </h2>
                  <div className="divider mb-4" style={{ backgroundColor: "#663455" }}></div>
                </div>
                <Card.Text
                  style={{
                    textAlign: "justify",

                    lineHeight: "1.6",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  className="my-text"
                >
                  Benvenuti nel salone di <strong>Art Paris</strong>, che accoglie ogni giorno i clienti in un ambiente
                  famigliare che vuole far sentire a proprio agio ogni persona, una sorta di salotto che con un ambiente
                  allegro riesce a far sentire tutti come a casa propria. Siamo un team di{" "}
                  <strong>professionisti</strong> dedicati a fornire servizi di <strong>alta qualità</strong> per la
                  cura dei capelli e non solo. La nostra missione è di far sentire ogni cliente speciale e di far
                  risaltare la loro bellezza unica. Con <strong>anni di esperienza</strong> e un ambiente accogliente,
                  ci impegniamo a offrire un servizio eccezionale e personalizzato.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12} md={6} lg={4} className="mb-4">
            <img src={Immagine1} alt="Immagine 1" className="img-fluid fixed-dimension" />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-4">
            <img src={Immagine2} alt="Immagine 2" className="img-fluid fixed-dimension" />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-4">
            <img src={Immagine3} alt="Immagine 3" className="img-fluid fixed-dimension" />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-4">
            <img src={Immagine4} alt="Immagine 4" className="img-fluid fixed-dimension" />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-4">
            <img src={Immagine5} alt="Immagine 5" className="img-fluid fixed-dimension" />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-4">
            <img src={Immagine6} alt="Immagine 6" className="img-fluid fixed-dimension" />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-4">
            <img src={Immagine7} alt="Immagine 7" className="img-fluid fixed-dimension" />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-4">
            <img src={Immagine8} alt="Immagine 8" className="img-fluid fixed-dimension" />
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-4">
            <img src={Immagine9} alt="Immagine 9" className="img-fluid fixed-dimension" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Salon;
