import React from "react";
import Navbar2 from "./Navbar";
import { Container } from "react-bootstrap";
import ImmagineCopertina from "../assets/contact/img-copertina-art-paris (9).png";

import phoneIcon from "../assets/contact/telefono.png";
import locationIcon from "../assets/contact/pin.png";
import cellIcon from "../assets/contact/cel1.png";
import cellIcon2 from "../assets/contact/cel2.png";

const Contact = () => {
  return (
    <>
      <Navbar2 />
      <Container fluid style={{ padding: "0px", position: "relative", textAlign: "center" }}>
        <img
          src={ImmagineCopertina}
          alt="Copertina"
          className="img-fluid"
          id="responsive"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Container>
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-md-8">
            {/* <div>
              <h1 style={{ marginTop: 90, fontFamily: "Montserrat, sans-serif", fontSize: "2rem", color: "#663455" }}>
                CONTATTI
              </h1>
              <div className="divider mb-4" style={{ backgroundColor: "#663455" }}></div>
            </div> */}

            <div className="container text-center">
              <div className="row justify-content-center" style={{ marginTop: "70px" }}>
                <div className="col-lg-6 col-md-6 mb-3">
                  <div
                    className="card p-3 h-100"
                    style={{ backgroundColor: "#F6F6F6", color: "#663455", borderRadius: "0" }}
                  >
                    <div className="card-body d-flex flex-column align-items-center">
                      <div
                        className="icon"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <img src={locationIcon} alt="address icon" style={{ width: "50px" }} />
                      </div>
                      <p
                        className="card-text"
                        style={{ fontSize: "1.1rem", lineHeight: "1.6", fontFamily: "Montserrat, sans-serif" }}
                      >
                        <strong>Indirizzo:</strong>
                      </p>
                      <p
                        className="card-text"
                        style={{ fontSize: "1.1rem", lineHeight: "1.6", fontFamily: "Montserrat, sans-serif" }}
                      >
                        Via Petritoli 12/14 - 00138 Roma (RM)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-3">
                  <a href="tel:+39068804843" style={{ textDecoration: "none" }}>
                    <div
                      className="card p-3 h-100"
                      style={{ backgroundColor: "#F8F2F6", color: "#663455", borderRadius: "0" }}
                    >
                      <div className="card-body d-flex flex-column align-items-center ">
                        <div
                          className="icon"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <img src={phoneIcon} alt="phone icon" style={{ width: "50px" }} />
                        </div>
                        <p
                          className="card-text"
                          style={{ fontSize: "1.1rem", lineHeight: "1.6", fontFamily: "Montserrat, sans-serif" }}
                        >
                          <strong>Telefono del Salone:</strong>
                        </p>
                        <p
                          className="card-text"
                          style={{ fontSize: "1.1rem", lineHeight: "1.6", fontFamily: "Montserrat, sans-serif" }}
                        >
                          +39 06 8804843
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-6 col-md-6 mb-3">
                  <a href="tel:+393335970139" style={{ textDecoration: "none" }}>
                    <div
                      className="card p-3 h-100"
                      style={{ backgroundColor: "#F6F6F6", color: "#663455", borderRadius: "0" }}
                    >
                      <div className="card-body d-flex flex-column align-items-center">
                        <div
                          className="icon"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <img src={cellIcon2} alt="person icon" style={{ width: "50px" }} />
                        </div>
                        <p
                          className="card-text"
                          style={{ fontSize: "1.1rem", lineHeight: "1.6", fontFamily: "Montserrat, sans-serif" }}
                        >
                          <strong>Alessandra Orsini:</strong>
                        </p>
                        <p
                          className="card-text"
                          style={{ fontSize: "1.1rem", lineHeight: "1.6", fontFamily: "Montserrat, sans-serif" }}
                        >
                          +39 3335970139
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-6 col-md-6 mb-3">
                  <a href="tel:+393389227935" style={{ textDecoration: "none" }}>
                    <div
                      className="card p-3 h-100"
                      style={{ backgroundColor: "#F8F2F6", color: "#663455", borderRadius: "0" }}
                    >
                      <div className="card-body d-flex flex-column align-items-center">
                        <div
                          className="icon"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <img src={cellIcon} alt="person icon" style={{ width: "50px" }} />
                        </div>
                        <p
                          className="card-text"
                          style={{ fontSize: "1.1rem", lineHeight: "1.6", fontFamily: "Montserrat, sans-serif" }}
                        >
                          <strong>Roberta Paris:</strong>
                        </p>
                        <p
                          className="card-text"
                          style={{ fontSize: "1.1rem", lineHeight: "1.6", fontFamily: "Montserrat, sans-serif" }}
                        >
                          +39 3389227935
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            {/* /// */}
          </div>
          {/* mappa */}
          <div className="col-md-8">
            <div className="map_main">
              {" "}
              <div className="map-responsive">
                {" "}
                <iframe
                  title="Google Map"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&q=Via+Petritoli,+12,+00138+Roma+RM"
                  width="600"
                  height="400"
                  frameBorder="0"
                  style={{ border: 0, width: "100%", marginBottom: "20px" }}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
